<template>
  <v-card>
    <v-card-title class="justify-center">
      <span>Cadastrar novo usuário</span>
    </v-card-title>

    <v-card-text>
      <v-row class="mt-1">
        <v-col cols="6">
          <v-text-field
            v-model="inputs.name"
            dense
            outlined
            label="Nome"
          />

          <v-text-field
            v-model="inputs.cpf"
            dense
            outlined
            label="CPF"
          />

          <v-text-field
            v-model="inputs.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            label="Nova senha"
            outlined
            dense
            hint="Necessário ter pelo menos 8 caracteres"
            @click:append="showPassword = !showPassword"
          ></v-text-field>

          <v-select
            v-model="inputs.companiesIdSelected"
            :items="companiesList"
            item-text="fantasy_name"
            item-value="id"
            label="Filial"
            outlined
            dense
          ></v-select>

          <v-autocomplete
            v-model="inputs.occupationsAndSectorsId"
            :items="occupationsAndSectorsList"
            item-text="occupation.name"
            item-value="id"
            label="Cargo"
            outlined
            dense
          ></v-autocomplete>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="inputs.email"
            dense
            outlined
            label="Email"
          />

          <v-text-field
            v-model="inputs.cellphone"
            dense
            outlined
            label="Telefone"
          />

          <v-text-field
            v-model="inputs.cloudId"
            dense
            outlined
            label="Cloud ID"
            type="number"
          />

          <v-select
            v-model="accessLevelSelected"
            :items="accessLevels"
            label="Nivel de acesso"
            outlined
            dense
          ></v-select>

          <v-select
            v-model="inputs.profileIdSelected"
            :items="profilesList"
            item-text="profile"
            item-value="id"
            label="Perfil"
            outlined
            dense
          ></v-select>
        </v-col>

        <v-switch
          v-model="userIsActive"
          color="success"
          label="Ativo"
          class="ml-2"
          inset
        ></v-switch>

        <v-switch
          v-model="userIsSupervisor"
          label="Supervisor"
          color="info"
          class="ml-8"
          inset
        ></v-switch>
      </v-row>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="info"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    companiesList: {
      type: [Array, String],
      required: true,
    },

    occupationsAndSectorsList: {
      type: [Array, String],
      required: true,
    },
  },

  data() {
    return {
      inputs: {
        name: '',
        cpf: '',
        email: '',
        password: '',
        cellphone: '',
        companie: '',
        cloudId: '',
        profileIdSelected: '',
        hierarchicalProfile: '',
        companiesIdSelected: '',
        occupationsAndSectorsId: '',
      },

      inputCompanie: '',
      userIsActive: false,
      showPassword: false,
      userIsSupervisor: false,

      accessLevels: ['1', '2', '3'],
      accessLevelSelected: '',
      profilesList: [],

      isLoading: false,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

  created() {
    this.getProfiles()
  },

  methods: {
    async sendModalData() {
      this.isLoading = true

      const body = {
        name: this.inputs.name.toUpperCase(),
        cpf: this.inputs.cpf,
        email: this.inputs.email,
        login: this.inputs.cpf,
        password: this.inputs.password,
        cellphone: this.inputs.cellphone,
        active: this.userIsActive,
        supervisor: this.userIsSupervisor,
        cloud_id: this.inputs.cloudId,
        profiles_id: this.inputs.profileIdSelected,
        hierarchical_profile: `nivel_${this.accessLevelSelected}`,
        companies_id: this.inputs.companiesIdSelected,
        occupations_sectors_id: this.inputs.occupationsAndSectorsId,
      }

      await axiosIns
        .post('/api/v1/records/user/store', body)
        .then(
          response => {
            this.sendSystemsSelected(response.data.data.id)

            this.showMessage({
              icon: 'success',
              title: 'Usuário cadastrado!',
              text: 'Feito com sucesso!',
            })
          },

          this.updatedTable(),
        )
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao cadastrar usuário!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    async getProfiles() {
      await axiosIns
        .get('api/v1/records/profile/index')
        .then(response => {
          this.profilesList = response.data.data
        })
        .catch(error => this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' }))
    },
  },
}
</script>

<style>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
