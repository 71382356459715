import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,[_c(VCard,[_c(VCardTitle,{staticClass:"pt-0 pb-0"},[_c('span',{staticClass:"mr-6"},[_vm._v(" Lista de usuários ")]),_c(VSpacer),_c(VTextField,{staticClass:"mt-6",attrs:{"label":"Buscar Nome, Cpf, Filial, Cod.Self, Função, Setor","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.findFilterResult()}},model:{value:(_vm.filterData),callback:function ($$v) {_vm.filterData=$$v},expression:"filterData"}}),_c(VBtn,{staticClass:"ml-4",attrs:{"outlined":""},on:{"click":function($event){return _vm.clearFilter()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterRemoveOutline)+" ")]),_vm._v(" Limpar filtros ")],1),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openModal('create')}}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" Novo ")],1),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"purple","outlined":""},on:{"click":function($event){return _vm.updateUsers()}}},[(!_vm.isLoadingUpdate)?_c('div',[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiUpdate)+" ")]),_c('span',[_vm._v("Atualizar funcionários")])],1):_c(VProgressCircular,{attrs:{"color":"white","indeterminate":""}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items-per-page":10,"loading":_vm.isLoading,"items":_vm.listOfFilteredItems,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.cpf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedCpfNumber(item.cpf))+" ")]}},{key:"item.cellphone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedPhoneNumberToBrazil(item.cellphone))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"color":"info"},on:{"click":function($event){return _vm.openModal('details', item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteRegister(_vm.endpoint, item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}])})],1)],1),_c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.showUserModal),callback:function ($$v) {_vm.showUserModal=$$v},expression:"showUserModal"}},[_c('userModal',{attrs:{"system-list":_vm.systemList,"companies-list":_vm.companiesList,"occupations-and-sectors-list":_vm.occupationsAndSectorsList},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showUserModal = false}}})],1),_c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.showUserDetails),callback:function ($$v) {_vm.showUserDetails=$$v},expression:"showUserDetails"}},[_c('userDetails',{key:_vm.dataUser.id,attrs:{"data-user":_vm.dataUser,"system-list":_vm.systemList,"companies-list":_vm.companiesList,"occupations-and-sectors-list":_vm.occupationsAndSectorsList},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showUserDetails = false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }