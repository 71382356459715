<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="pt-0 pb-0">
          <span class="mr-6"> Lista de usuários </span>

          <v-spacer />

          <v-text-field
            v-model="filterData"
            label="Buscar Nome, Cpf, Filial, Cod.Self, Função, Setor"
            class="mt-6"
            outlined
            dense
            @keyup.enter="findFilterResult()"
          />

          <v-btn
            class="ml-4"
            outlined
            @click="clearFilter()"
          >
            <v-icon
              size="30"
              class="me-2"
            >
              {{ icons.mdiFilterRemoveOutline }}
            </v-icon>
            Limpar filtros
          </v-btn>

          <v-btn
            color="info"
            class="ml-4"
            outlined
            @click="openModal('create')"
          >
            <v-icon class="mr-1">
              {{ icons.mdiPlaylistPlus }}
            </v-icon>
            Novo
          </v-btn>

          <v-btn
            color="purple"
            class="ml-4"
            outlined
            @click="updateUsers()"
          >
            <div v-if="!isLoadingUpdate">
              <v-icon
                size="25"
                class="mr-2"
              >
                {{ icons.mdiUpdate }}
              </v-icon>
              <span>Atualizar funcionários</span>
            </div>

            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
        </v-card-title>

        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items-per-page="10"
              :loading="isLoading"
              :items="listOfFilteredItems"
              loading-text="Carregando dados..."
            >
              <template v-slot:item.cpf="{ item }">
                {{ formattedCpfNumber(item.cpf) }}
              </template>

              <template v-slot:item.cellphone="{ item }">
                {{ formattedPhoneNumberToBrazil(item.cellphone) }}
              </template>

              <template v-slot:item.edit="{ item }">
                <v-icon
                  color="info"
                  @click="openModal('details', item)"
                >
                  {{ icons.mdiPencil }}
                </v-icon>
              </template>

              <template v-slot:item.delete="{ item }">
                <v-icon
                  color="error"
                  @click="deleteRegister(endpoint, item.id)"
                >
                  {{ icons.mdiDelete }}
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-dialog
          v-model="showUserModal"
          width="600"
        >
          <userModal
            :system-list="systemList"
            :companies-list="companiesList"
            :occupations-and-sectors-list="occupationsAndSectorsList"
            @updatedTable="updatedTable()"
            @close="showUserModal = false"
          ></userModal>
        </v-dialog>

        <v-dialog
          v-model="showUserDetails"
          width="600"
        >
          <userDetails
            :key="dataUser.id"
            :data-user="dataUser"
            :system-list="systemList"
            :companies-list="companiesList"
            :occupations-and-sectors-list="occupationsAndSectorsList"
            @updatedTable="updatedTable()"
            @close="showUserDetails = false"
          ></userDetails>
        </v-dialog>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiDelete, mdiFilterPlusOutline, mdiFilterRemoveOutline, mdiPencil, mdiPlaylistPlus, mdiUpdate,
} from '@mdi/js'
import userDetails from './userDetails.vue'
import userModal from './userModal.vue'

export default {
  components: {
    userModal,
    userDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      isLoading: false,
      isLoadingUpdate: false,

      showUserModal: false,
      showUserDetails: false,
      endpointDelete: 'api/v1/records/user/destroy/',

      headers: [
        { text: 'NOME', value: 'name' },
        { text: 'EMPRESA', value: 'company.fantasy_name' },
        { text: 'CPF', value: 'cpf' },
        { text: 'EMAIL', value: 'email' },
        { text: 'TELEFONE', value: 'cellphone' },
        { text: 'EDITAR', value: 'edit', align: 'center' },
        { text: 'DELETAR', value: 'delete', align: 'center' },
      ],

      user: {},
      sector: {},
      dataUser: {},
      infoBase: {},
      occupation: {},
      filterData: [],
      systemList: [],
      itemsTable: [],
      companiesList: [],
      listOfFilteredItems: [],
      occupationsAndSectorsList: [],

      icons: {
        mdiPencil,
        mdiDelete,
        mdiUpdate,
        mdiPlaylistPlus,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getProfiles()
    this.getCompanies()
    this.getSystemList()
    this.getOccupationsAndSectors()
  },

  methods: {
    async getProfiles() {
      this.isLoading = true

      await axiosIns
        .get('api/v1/records/user/index')
        .then(response => {
          const { data } = response.data

          this.itemsTable = data
          this.listOfFilteredItems = data
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => { this.isLoading = false })
    },

    async getCompanies() {
      await axiosIns
        .get('api/v1/records/company/index')
        .then(response => {
          this.companiesList = response.data.data
        })
        .catch(error => this.showErrorMessage(error))
    },

    async getOccupationsAndSectors() {
      await axiosIns
        .get('api/v1/records/occupation/index')
        .then(response => {
          const { data } = response.data

          this.sectors = data.sector
          this.occupations = data.occupation
          this.occupationsAndSectorsList = data
        })
        .catch(error => this.showErrorMessage(error))
    },

    async getSystemList() {
      await axiosIns
        .get('api/v1/records/system/index')
        .then(response => {
          this.systemList = response.data.data
        })
        .catch(error => this.showErrorMessage(error))
    },

    async updateUsers() {
      this.isLoadingUpdate = true

      await axiosIns
        .get('api/v1/records/user/update_cloud_users')
        .then(response => this.showMessage({
          title: 'Dados atualizados com sucesso',
          text: response.data.data[0],
          icon: 'success',
          timer: 5000,
        }))
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoadingUpdate = false
          this.updatedTable()
        })
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(({
        // eslint-disable-next-line camelcase
        name, cpf, company, cod_self, occupation_sector,
      }) => {
        const occupation = occupation_sector?.occupation ?? { name: '' }
        const sector = occupation_sector?.sector ?? { name: '' }
        const fantasyName = company?.fantasy_name ?? ''

        const lowerName = name.toLowerCase()
        const lowerCodSelf = String(cod_self).toLowerCase()
        const lowerOccupationName = occupation.name.toLowerCase()
        const lowerSectorName = sector.name.toLowerCase()
        const lowerFantasyName = fantasyName.toLowerCase()
        const cpfFormatted = this.formattedCpfNumber(cpf)

        return (
          cpf.includes(filterDataLower)
          || cpfFormatted.includes(filterDataLower)
          || lowerName.includes(filterDataLower)
          || lowerCodSelf.includes(filterDataLower)
          || lowerOccupationName.includes(filterDataLower)
          || lowerSectorName.includes(filterDataLower)
          || lowerFantasyName.includes(filterDataLower)
        )
      })
    },

    showErrorMessage(error) {
      this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
    },

    clearFilter() {
      this.filterData = ''
      this.updatedTable()
    },

    updatedTable() {
      this.getProfiles()
    },

    openModal(type, data = {}) {
      const modal = {
        create: () => { this.showUserModal = true },
        details: () => {
          this.showUserDetails = true
          this.dataUser = data
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error('Modal type does not exist in openModal function')
    },
  },
}
</script>
