<template>
  <v-card>
    <v-card-title class="justify-center">
      <span>Editar usuário</span>
    </v-card-title>

    <v-card-text :key="dataUser.id">
      <v-row class="mt-1">
        <v-col cols="6">
          <v-text-field
            v-model="dataUser.name"
            dense
            outlined
            label="Nome"
          />

          <v-text-field
            v-model="dataUser.cpf"
            dense
            outlined
            label="CPF"
          />

          <v-text-field
            v-model="inputs.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
            label="Nova senha"
            outlined
            dense
            hint="Necessário ter pelo menos 8 caracteres"
            @click:append="showPassword = !showPassword"
          />

          <v-text-field
            v-model="dataUser.cod_self"
            dense
            outlined
            label="Cod. Self"
          />

          <v-text-field
            v-model="dataUser.company.fantasy_name"
            label="Filial"
            dense
            outlined
            readonly
          />

          <v-text-field
            v-model="verifyUserSectorExist"
            label="Cargo"
            dense
            outlined
            readonly
          />
          <v-text-field
            v-model="dataUser.occupation_sector.sector.name"
            label="Setor"
            dense
            outlined
            readonly
          />

          <v-divider />
          <br />

          <v-select
            v-model="inputs.companiesIdSelected"
            :items="companiesList"
            item-text="fantasy_name"
            item-value="id"
            label="Alterar filial"
            outlined
            dense
          />

          <v-autocomplete
            v-model="inputs.occupationsAndSectorsId"
            :items="occupationsAndSectorsList"
            :item-text="item => `${item.occupation.name} - ${item.sector.name}`"
            item-value="id"
            label="Alterar cargo"
            outlined
            dense
          />

          <v-switch
            v-model="dataUser.supervisor"
            label="Supervisor"
            color="info"
            inset
          />

          <v-switch
            v-model="dataUser.active"
            color="success"
            label="Ativo"
            inset
          />
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="dataUser.email"
            dense
            outlined
            label="Email"
          />

          <v-text-field
            v-model="dataUser.login"
            dense
            outlined
            label="Login"
          />

          <v-text-field
            v-model="dataUser.cellphone"
            dense
            outlined
            label="Telefone"
          />

          <v-text-field
            v-model="dataUser.cloud_id"
            dense
            outlined
            label="Cloud ID"
            type="number"
          />

          <v-text-field
            v-model="dataUser.hierarchical_profile"
            label="Nivel"
            dense
            outlined
            readonly
          />

          <v-text-field
            v-model="computedProfile"
            label="Perfil"
            dense
            outlined
            readonly
          />

          <div style="height: 66px;"></div>

          <v-divider />
          <br />

          <v-select
            v-model="accessLevelSelected"
            :items="accessLevels"
            label="Alterar Nivel"
            outlined
            dense
          />

          <v-select
            v-model="inputs.profileIdSelected"
            :items="profilesList"
            item-text="profile"
            item-value="id"
            label="Alterar Perfil"
            outlined
            dense
          />
        </v-col>
      </v-row>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="info"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    dataUser: {
      type: [Object, String],
      required: true,
    },

    companiesList: {
      type: [Array, String],
      required: true,
    },

    occupationsAndSectorsList: {
      type: [Array, String],
      required: true,
    },

    systemList: {
      type: [Array, String],
      required: true,
    },
  },

  data() {
    return {
      inputs: {
        password: '',
        companie: '',
        systemIdSelected: '',
        profileIdSelected: '',
        hierarchicalProfile: '',
        companiesIdSelected: '',
        occupationsAndSectorsId: '',
      },

      isLoading: false,
      userIsActive: false,
      showPassword: false,
      userIsSupervisor: false,

      profilesList: [],
      systemListFromUser: [],

      accessLevels: ['1', '2', '3'],
      accessLevelSelected: '',

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },

  computed: {
    verifyUserSectorExist() {
      if (!this.dataUser.occupation_sector) {
        return 'Não atribuído'
      }

      return this.dataUser?.occupation_sector?.occupation?.name
    },

    computedProfile() {
      return this.dataUser?.profile?.profile ?? 'Não atribuído'
    },
  },

  created() {
    this.getProfiles()
  },

  methods: {
    async sendModalData() {
      this.isLoading = true

      const body = {
        name: this.dataUser.name.toUpperCase(),
        cpf: this.dataUser.cpf,
        cod_self: this.dataUser.cod_self,
        email: this.dataUser.email,
        login: this.dataUser.login,
        cellphone: this.dataUser.cellphone,
        active: this.dataUser.active,
        supervisor: this.dataUser.supervisor,
        cloud_id: this.dataUser.cloud_id,
        profiles_id: !this.inputs.profileIdSelected ? this.dataUser.profile?.id : this.inputs.profileIdSelected,
        hierarchical_profile: !this.accessLevelSelected
          ? this.dataUser.hierarchical_profile
          : `nivel_${this.accessLevelSelected}`,
        companies_id: !this.inputs.companiesIdSelected ? this.dataUser.company?.id : this.inputs.companiesIdSelected,
        occupations_sectors_id: !this.inputs.occupationsAndSectorsId
          ? this.dataUser.occupation_sector?.id
          : this.inputs.occupationsAndSectorsId,
      }

      if (this.inputs.password) {
        Object.assign(body, { password: this.inputs.password })
      }

      await axiosIns
        .put(`api/v1/records/user/update/${this.dataUser.id}`, body)
        .then(
          () => {
            this.showMessage({
              icon: 'success',
              title: 'Usuário atualizado!',
              text: 'Dados do usuário atualizados!',
            })

            if (this.inputs.password) {
              this.inputs.password = ''
            }
          },

          this.updatedTable(),
        )
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao atualizar dados do usuário!',
            text: error,
          })
        }).finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    async updateSystemList() {
      const body = {
        systems_id: this.inputs.systemIdSelected,
      }

      await axiosIns
        .post(`api/v1/records/user_system/update/${this.dataUser.user_system.id}`, body)
        .then(() => {
          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao cadastrar sistemas!',
            text: error,
          })
        })
    },

    async getProfiles() {
      await axiosIns.get('api/v1/records/profile/index').then(response => {
        this.profilesList = response.data.data
      }).catch(error => this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' }))
    },
  },
}
</script>

<style scoped>
/* Retira o incrementador padrão dos dataUser do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
